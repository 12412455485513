import React from "react";
import '../../styles/semantic.min.css';
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import Office from "../../components/about-us/Office";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import DesktopContainer from "../../container/DesktopContainer";
import PageHeader from "../../components/utils/PageHeader";
import {graphql, StaticQuery} from "gatsby";
import {Container, Grid, Segment} from "semantic-ui-react";
import AboutUsNavigation from "../../components/navigation/AboutUsNavigation";
import styles from "../../components/about-us/aboutUs.module.css";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import cx from "classnames";
import layout from "../../templates/layout.module.css";

export default () =>
    <LayoutContainer>
        <SEO title={'Geschäftsstelle'}/>
                <StaticQuery query={officeQuery}
                             render={
                                 data => {
                                     return(
                                         <>
                                             <MobileTabletContainer>
                                                 <MainNavigation/>
                                                 <PageHeader title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                                                             subHeader={data.allMarkdownRemark.edges[0].node.frontmatter.summary}/>
                                                 <MobileBgWrapper>
                                                     <Container>
                                                         <Segment>
                                                             <Container className={cx(styles.officeContainer, styles.container)}
                                                                 dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}>
                                                             </Container>
                                                         </Segment>
                                                             <Office/>
                                                     </Container>
                                                 </MobileBgWrapper>
                                                 <Footer/>
                                             </MobileTabletContainer>
                                             <DesktopContainer>
                                                 <div className={layout.pageWrapper}>
                                                 <MainNavigation/>
                                                 <Container fluid>
                                                     <PageHeader title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                                                                 subHeader={data.allMarkdownRemark.edges[0].node.frontmatter.summary}/>
                                                     <Grid centered columns={2}>
                                                         <Grid.Column width={11}>
                                                             <Segment>
                                                                 <Container className={cx(styles.officeContainer, styles.container)}
                                                                            dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}>
                                                                 </Container>
                                                             </Segment>
                                                                 <Office/>
                                                             <AboutUsNavigation/>
                                                         </Grid.Column>
                                                         <Grid.Column width={4}/>
                                                     </Grid>
                                                 </Container>
                                                 <Footer/>
                                                 </div>
                                             </DesktopContainer>
                                         </>
                                     )
                                 }
                             }
                />

    </LayoutContainer>

const officeQuery = graphql`
query officeQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "about-us" }
            kind: { eq: "office" }
         }
      }
      limit: 1
      ){
      edges {
        node {
            html
            frontmatter {
                title
                summary
            }
        }
      }
    }
}
`;